import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p>{`動画では、説明のために「極論を言うと、その楽器の担当帯域以外はいらないと言える」と言っていますが、これはもちろん極論です。EQの目的は、あくまでも「高さごとに、リファレンスと合わせる」ことなので、もしリファレンスに「いらない帯域」があるのなら、`}<strong parentName="p">{`その帯域もリファレンスと同じ大きさにします。`}</strong></p>
      <p>{`リファレンスと比べもせずに`}</p>
      <p>{`「なーんだいらないのかー！全部削っちゃえー！」`}</p>
      <p>{`では、`}<strong parentName="p">{`もちろんリファレンスと同じにはなりません。`}</strong></p>
      <p><strong parentName="p">{`補足２：`}</strong></p>
      <p>{`コンプを使うときは、「コンプを通す前と同じ大きさになるように、コンプのゲインを設定する」という作業が必要でした。`}</p>
      <p>{`EQでも、この作業は必要です。なぜなら、EQを通した後で大きさが変わってしまうと、最初に「大きさ」をリファレンスに合わせた意味がなくなってしまうからです。`}</p>
      <p>{`ですから、EQのボリュームを使って、EQから出てくる音量を「EQを通す前」と同じ大きさに設定して下さい。`}<br parentName="p"></br>{`
`}{`厳密に言うと、最初に「大きさ」で設定した、「リファレンスと同じ大きさ」を保って下さい。`}<strong parentName="p">{`聞いた感じで、大体同じになっていればOK`}</strong>{`です。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      